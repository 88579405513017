import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { Right } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'

// TODO: Add copy when available
const texts = {
  right: {
    links: [
      {
        link: 'our partners',
        url: '/our-platform/our-partners',
        variant: 'mdDown',
      },
      {
        link: "we're certified",
        url: '/our-platform/certifications',
        variant: 'all',
      },
    ],
    pretitle: 'Third-party integrations',
    text:
      'Our fully API-based solution gives you the freedom to integrate whatever third parties you like. Need more added? Connect with our flexible API or fire an RFC and we will get it done.',
    title: 'perfect partners',
  },
}

const PartnersPage: React.FC = () => (
  <>
    <Right>
      <SideNavigationLink
        variant="light"
        position="right"
        delay={1.1}
        time={1}
        url="/our-platform/our-partners"
        text="our partners"
      />
    </Right>
    <Page
      content={
        <PageWrapper>
          <WithSlideShowCheck
            fullWidth
            mobileContent={[<Text isMobile {...texts.right} />]}
          >
            <Box row>
              <Box col={0.6} />
              <Box col={0.4}>
                <Text {...texts.right} />
              </Box>
            </Box>
          </WithSlideShowCheck>
        </PageWrapper>
      }
      settings={{
        backgroundColor: '#113f4f',
        contentAnimationVariants: pageFadeInOut(),
        useCircularWipe: false,
      }}
    />
  </>
)

export default PartnersPage
